import React from "react"
import {graphql} from "gatsby";
import Layout from "/src/components/sitewide/layout";

export default function Legal({data}) {

    const { markdownRemark } = data
    const { frontmatter, html } = markdownRemark

    return (
        <Layout>
            <h1 className="text-2xl"><strong>{frontmatter.title}</strong></h1>
            <div className="text-secondary markdown" dangerouslySetInnerHTML={{ __html: html }}/>
        </Layout>
    )

}

export const query = graphql`
query($slug: String!) {
  markdownRemark(frontmatter: {slug: { eq: $slug} }) {
    html
    frontmatter {
        title
        slug
    }
  }
}
`